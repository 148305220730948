import React from 'react'

import { breakpoints } from 'src/ui/dimensions'
import { HBox, Wrapper } from 'src/ui/layout'
import { styled } from 'src/ui/theme'
import { Body, Header, Label } from 'src/ui/typography'

import { Video } from 'src/ui/molecules'
import { TReadyTarget } from 'src/ui/molecules/video/video'

import { Props } from './types'

const Grid = styled.div<{ activeCol: number }>`
  display: flex;
  flex-flow: row;
  margin: 0 -24px;
  overflow: hidden;

  @media (max-width: ${breakpoints.tablet}px) {
    flex-flow: column;
    margin: 0;
  }
`

const Col = styled.div<{ active: boolean }>`
   /* width: ${({ active }) =>
     active ? '43.9%' : '24.2%'}; // 3 видео - макс */
  width: ${({ active }) => (active ? '60.9%' : '40.2%')}; // 2 видео

  transition: all 0.3s;
  margin: 0 24px;

  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    margin: 0;
    margin-bottom: 48px;
  }
`

export const Peoples: React.FC<Props> = ({ data }) => {
  const [activeCol, setActiveCol] = React.useState(0)
  const [videos, setVideos] = React.useState<{
    [videoId: string]: TReadyTarget
  }>({})

  const onPlayHandler = (videoId: string) => {
    for (const key in videos) {
      if (videos.hasOwnProperty(key) && key !== videoId) {
        videos[key].pauseVideo()
      }
    }
  }

  return (
    <Wrapper>
      <Label weight="bold">ЛЮДИ</Label>
      <HBox height={8} />
      <Header size="h2" as="h2">
        Кто делает KODE
      </Header>
      <HBox height={64} />

      <Grid activeCol={activeCol}>
        {data.map((item, key) => (
          <Col key={item.videoId} active={activeCol === key}>
            <Video
              videoId={item.videoId}
              height={436}
              type={item.type}
              onPause={() => null}
              onPlay={() => {
                setActiveCol(key)
                onPlayHandler(item.videoId)
              }}
              onReady={(e) =>
                setVideos({ ...videos, [item.videoId]: e.target })
              }
            />
            <HBox />
            <Header size="h5">{item.name}</Header>
            <HBox height={8} />
            <Body weight="medium">{item.position}</Body>
            <HBox height={8} />
            <Body>{item.description}</Body>
          </Col>
        ))}
      </Grid>
    </Wrapper>
  )
}
