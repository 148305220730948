import React from 'react'

import {
  Cities,
  HeroBlock,
  JobList,
  Peoples,
  Rest,
  WhyWe,
} from 'src/features/main-page'
import { Meta } from 'src/features/seo'
import { RootLayout } from 'src/layouts'
import { useDimensions } from 'src/ui/dimensions'
import { HBox } from 'src/ui/layout'

import { graphql, useStaticQuery } from 'gatsby'

import { getVacanciesByDepartments } from 'src/shared/selectors'
import { Achievements } from 'src/ui/organisms/achievements'

import { TVideoType } from 'shared/constants'

type Props = {}

const Index: React.FC<Props> = () => {
  const data = useStaticQuery<GatsbyTypes.IndexDataQuery>(graphql`
    query IndexData {
      allFile(filter: { relativePath: { regex: "/index//i" } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid(maxWidth: 1900) {
                srcSet
                base64
                src
                aspectRatio
              }
            }
          }
        }
      }
      strapi {
        vacancies(where: { status: "publish" }) {
          id
          slug
          title
          hot
          order
          levels {
            name
          }
          regions {
            name
            id
          }
          excerpt
          department {
            name
            id
            description
            order
          }
          conditions {
            name
            id
          }
          levels {
            id
            name
          }
        }
      }
    }
  `)

  const images = data.allFile.edges.reduce<TImages>((acc, img) => {
    return {
      ...acc,
      [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
    }
  }, {})

  const peoplesData = [
    {
      videoId: 'ab245d6abf467fa42f070a8299e47ef3',
      name: 'Алексей Козак',
      type: 'rutube' as TVideoType,
      position: 'CEO KODE',
      description:
        'Интервью об основании компании, корпоративной культуре, стажировках и о том, кому точно зайдёт работать в KODE.',
    },
    {
      videoId: '0b001d0f4ad197ca8acfdf4b5ebee048',
      name: 'Вадим Кузенков',
      type: 'rutube' as TVideoType,
      position: 'Head of PMO',
      description:
        'Рассказывает, как решился на переезд всей семьёй на другой конец страны, о тюнинге процессов внутри компании мобильной разработки и о том, как ему в этом деле помогает осознанность.',
    },
  ]

  const { isMobile } = useDimensions()

  const vacanciesByDepartmentsData = getVacanciesByDepartments(data)

  let byDepartmentsList: TVacancyShortList = []

  let hotVacancies: TVacancy[] = []

  if (vacanciesByDepartmentsData) {
    hotVacancies = vacanciesByDepartmentsData.vacanciesByDepartments.reduce<
      TVacancy[]
    >((acc, current) => [...acc, ...current.vacancies.filter((v) => v.hot)], [])

    byDepartmentsList = vacanciesByDepartmentsData.vacanciesByDepartments.reduce<
      TVacancyShortList
    >((acc, cur) => {
      let regions: { [name: string]: number } = {}

      cur.vacancies.forEach((v) => {
        v.regions.forEach((r) => {
          if (regions[r]) {
            regions[r] = regions[r] + 1
          } else {
            regions[r] = 1
          }
        })
      })

      let regionsRes: {
        count: number
        name: string
      }[] = []

      for (const key in regions) {
        if (regions.hasOwnProperty(key)) {
          regionsRes = [...regionsRes, { name: key, count: regions[key] }]
        }
      }

      return [
        ...acc,
        {
          departmentName: cur.departmentName,
          departmentId: cur.id,
          regions: regionsRes,
          description: cur.description,
        },
      ]
    }, [])
  }

  const sliderItems = [
    {
      image: images['hero'],
    },
    {
      image: images['dagestan'],
    },
    {
      image: images['julia'],
    },
    {
      image: images['summer-party'],
    },
    {
      image: images['conference'],
    },
  ]

  const sliderText = {
    label: 'КАРЬЕРА В KODE',
    title: 'Стань тем,\nкем мечтаешь',
    text:
      'В KODE фокусируются на амбициях и способностях сотрудника. С нами легко строить карьеру мечты и создавать полезные продукты для известных компаний и сотен тысяч пользователей.',
  }

  return (
    <RootLayout withoutNavShadow HeaderComponent={<Achievements />}>
      <Meta
        title="Работа в IT компании мобильной разработки — KODE"
        description="Ищете работу в IT? Компания KODE приглашает тебя стать частью дружной команды ✔ Ищем UX-дизайнеров, аналитиков, QA, разработчиков ✔ Офис в Калининграде"
      />
      {isMobile ? <HBox /> : null}
      <HeroBlock
        sliderItems={sliderItems}
        sliderText={sliderText}
        images={images}
      />
      {isMobile ? <HBox height={80} /> : <HBox height={112} />}
      <WhyWe images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={112} />}
      <Peoples data={peoplesData} />
      {isMobile ? <HBox height={72} /> : <HBox height={112} />}
      <Cities images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={112} />}
      <Rest images={images} />
      {isMobile ? <HBox height={48} /> : <HBox height={48} />}

      {vacanciesByDepartmentsData &&
      vacanciesByDepartmentsData.vacanciesByDepartments.length > 0 ? (
        <JobList byDepartmentsList={byDepartmentsList} hotList={hotVacancies} />
      ) : null}

      <HBox height={72} />
    </RootLayout>
  )
}

export default Index
