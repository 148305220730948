import React from 'react'

import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { HBox, Wrapper } from 'src/ui/layout'

import { Video } from 'src/ui/molecules'
import { SwiperSlider } from 'src/ui/organisms'
import { Body, Header, Label } from 'src/ui/typography'

import { TReadyTarget } from 'src/ui/molecules/video/video'
import { Props } from './types'

export const Peoples = ({ data }: Props) => {
  const [videos, setVideos] = React.useState<{
    [videoId: string]: TReadyTarget
  }>({})

  const onPlayHandler = (videoId: string) => {
    for (const key in videos) {
      if (videos.hasOwnProperty(key) && key !== videoId) {
        videos[key].pauseVideo()
      }
    }
  }

  return (
    <Wrapper>
      <Label weight="bold">ЛЮДИ</Label>
      <HBox height={8} />
      <Header size="h4" as="h2">
        Кто делает KODE
      </Header>
      <HBox />

      <SwiperSlider onSwipe={() => onPlayHandler('')}>
        {data.map((item, i) => (
          <SwiperSlide key={i}>
            <Video
              videoId={item.videoId}
              height={218}
              type={item.type}
              onPause={() => null}
              onPlay={() => onPlayHandler(item.videoId)}
              onReady={(e) =>
                setVideos({ ...videos, [item.videoId]: e.target })
              }
            />
            <HBox />
            <Header size="h5">{item.name}</Header>
            <HBox height={8} />
            <Body weight="medium">{item.position}</Body>
            <HBox height={8} />
            <Body>{item.description}</Body>
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </Wrapper>
  )
}
